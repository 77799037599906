@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;700&display=swap');

:root {
  /* Text Colors */
  --text-color: rgb(73, 73, 73);
  --text-color-light: #ffffff;
  --text-color-muted: #797979;
  --text-color-node: #2b2b2b;
  --text-color-tutorial: #000000;
  --text-color-calendar: #ffffff;
  
  /* Background Colors */
  --background-color: white;
  --background-color-node: #ffffff;
  --button-background: #f0f0f0;
  --button-hover-background: #e6e6e6;
  --header-background: rgb(255, 255, 255);
  --popup-background: #ffffff;
  --calendar-background: white;
  --calendar-navigation-background: #5655A4;
  --login-background: white;
  --login-container-background: #5654A3;
  --output-row-background: #f8f8f8;
  
  /* Border Colors */
  --border-color: #ccc;
  --card-border-color: #BFC5CE;
  --header-border-bottom: rgba(0, 0, 0, 0.1);
  --selected-node-border-color: rgb(0, 0, 152);
  --search-input-border-color: #ccc;
  --search-input-focus-border-color: #007bff;
  --tiny-checkbox-border-color: #cccccc;
  
  /* Table Colors */
  --table-header-background: #f0f0f0;
  --table-row-hover-background: #f5f5f5;
  
  /* Calendar Colors */
  --calendar-tile-hover-background: #f0f0f0;
  --calendar-tile-active-background: #5655A4;
  --calendar-tile-now-background: #e6f4ff;
  --calendar-neighboring-month-color: #757575;
  
  /* Status Colors */
  --status-new-color: var(--text-color);
  --status-needs-review-color: #ffcc8e;
  --status-reviewed-color: #a5dbff;
  --status-addressed-color: #b4e2c7;
  --status-archived-color: #c4c7dd;
  --status-icon-error-color: #ffbc13; 
  
  /* Chart Theme Colors */
  --chart-theme-color-1: #7c9be6;
  --chart-theme-color-2: #b0b3e6;
  --chart-theme-color-3: #918de4;
  --chart-theme-color-4: #c4c9e6;
  --chart-theme-color-5: #c4ebad;
  --chart-theme-color-6: #96dee8;
  --chart-theme-color-7: #f9d6a0;
  --chart-theme-color-8: #f4b2b2;
  --chart-theme-color-9: #a8e6cf;
  --chart-theme-color-10: #a0d8e6;
  
  /* Interactive Element Colors */
  --link-color: #007bff;
  --link-hover-color: #7b7b7b;
  --tooltip-background: rgba(0, 0, 0, .9);
  --tooltip-text-color: #fff;
  --tooltip-background-tutorial: #e0feff;
  
  /* Message Colors */
  --message-background-color: #6f6abe;
  --message-background-hover-color: #52518d;
  --message-text-color: #ffffff;
  
  /* Utility Colors */
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --divider-color: #e0e0e0;
  --spinner-border-color: rgba(0, 0, 0, 0.1);
  --spinner-border-top-color: #3498db;
  --edge-color-node: #7d7d7d;
  
  /* Login-specific Colors */
  --login-button-background: #0093cd;
  --login-button-background-hover: #316d7e;
  --login-button-text-color: white;
  --login-text-color: #ffffff;

  
  /* Chart Settings */
  --chart-label-text-color: #2c2c2c;
  --chart-border-width: 0;
  --chart-border-color: #2c2c2c;
  
  /* Feedback Colors */
  --thumb-up-color: #3cd660;
  --thumb-down-color: #cc5345;
  
  /* Assets */
  --login-icon-image: url('./assets/white_qurrent_icon.png');
  --first-card-width: 14em;
  --second-card-width: 14em;
  --first-two-cards-width: calc(var(--first-card-width) + var(--second-card-width));
}

.dark-mode {
  /* Core Colors - Dark Mode */
  --text-color: #c3c3c3;
  --text-color-node: #c3c3c3;
  --background-color: #202020;
  --background-color-node: #121212;
  
  /* Background Colors - Dark Mode */
  --button-background: #333;
  --button-hover-background: #444;
  --header-background: #202020;
  --popup-background: #333;
  --calendar-background: #202020;
  --calendar-navigation-background: #333;
  --login-background: #131313;
  --login-container-background: #1c1c1c;
  --output-row-background: #232323;

  
  /* Border Colors - Dark Mode */
  --border-color: #444;
  --card-border-color: #444;
  --header-border-bottom: rgba(255, 255, 255, 0.1);
  --selected-node-border-color: rgb(214, 214, 255);
  --search-input-border-color: #444;
  --search-input-focus-border-color: #86bdfc;
  --tiny-checkbox-border-color: #444;
  
  /* Table Colors - Dark Mode */
  --table-header-background: #333;
  --table-row-hover-background: #444;
  
  /* Calendar Colors - Dark Mode */
  --calendar-tile-hover-background: #4443A1;
  --calendar-tile-active-background: #4443A1;
  --calendar-tile-now-background: #333;
  --calendar-neighboring-month-color: #555;
  
  /* Status Colors - Dark Mode */
  --status-new-color: #8e8a2b;
  --status-needs-review-color: #915720;
  --status-reviewed-color: #36607b;
  --status-addressed-color: #36704e;
  --status-archived-color: #494e5d;
  --status-icon-error-color: #f1ff34; 
  
  /* Interactive Element Colors - Dark Mode */
  --link-color: #469cff;
  --link-hover-color: #888888;
  --tooltip-background: rgba(255, 255, 255, 0.9);
  --tooltip-text-color: #000;
  
  /* Message Colors - Dark Mode */
  --message-background-color: rgb(189, 215, 255);
  --message-background-hover-color: rgb(56, 73, 99);
  --message-text-color: #dadada;
  
  /* Utility Colors - Dark Mode */
  --box-shadow-color: rgba(255, 255, 255, 0.1);
  --divider-color: #444;
  --spinner-border-color: rgba(255, 255, 255, 0.1);
  --spinner-border-top-color: #86dcfc;
  
  /* Login-specific Colors - Dark Mode */
  --login-button-background: #0083b7;
  --login-button-background-hover: #547ea3;
  --login-text-color: #c3c3c3;
  
  /* Chart Settings - Dark Mode */
  --chart-label-text-color: #ffffff;
  --chart-border-color: #cccccc;
  
  /* Feedback Colors - Dark Mode */
  --thumb-up-color: #12a233;
  --thumb-down-color: #cb2715;
  
  /* Assets - Dark Mode */
  --login-icon-image: url('./assets/qurrent_icon.png');
  --first-card-width: 14em;
  --second-card-width: 14em;
  --first-two-cards-width: calc(var(--first-card-width) + var(--second-card-width));

}

body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.App-main {
  background-color: var(--background-color);
  position: fixed;
  margin-top: 52px;
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 1;
  margin-bottom: 60px;
  width: calc(100% - 40px);
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-background);
  background-size: cover;
  background-position: center;
  color: var(--text-color);
  padding: 0.7rem 0rem;
  backface-visibility: hidden;
  margin-left: 20px;
  margin-right: 20px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.App-metrics .App-header,
.App-workflow .App-header,
.App-feedback .App-header {
  border-bottom: 1px solid var(--border-color);
}

.App-header-left {
  display: flex;
  align-items: center;
}

.App-header-left:nth-child(2) {
  margin-left: 0px;
}

.App-header-right {
  display: flex;
  align-items: center;
  height: 100%;
}

.App-account {
  position: relative;
  display: flex;
  align-items: center;
}

.App-username-container {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.App-username {
  color: var(--text-color);
  font-size: 0.85rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.App-dropdown {
  z-index: 1000;
  position: relative;
}

.App-dropdown-content {
  position: absolute;
  right: 0;
  top: 100%; 
  box-shadow: 0px 1px 1px 1px var(--box-shadow-color);
  background-color: var(--popup-background);
  min-width: 280px;
  z-index: 1000;
  border-radius: 2px;
  display: block;  
}

.App-dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-size: 0.8rem;
  padding: 0;
  margin-left: 5px;
}

.App-dropdown-content a {
  color: var(--text-color);
  padding: 10px;
  text-decoration: none;
  display: block;
  z-index: 2000;
}

.dropdown-icon {
  height: .75em;
  width: .75em;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.customer-view-button {
  height: 40px;
}

.logout-button,.dark-mode-button, .observable-view-toggle-button, .customer-view-button, .environment-dropdown-button, .tooltip-tutorial-button {
  font-size: .75em;
  display: flex;
  align-items: center;
  justify-content: start;
  align-content: center;
  width: 100%;
  padding-left: 10px; 
  padding-right: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: var(--button-background);
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
}

.environment-dropdown-button-option {
  font-size: .75em;
  display: flex;
  align-items: center;
  justify-content: start;
  align-content: center;
  width: 100%;
  padding-left: 10px; 
  padding-right: 10px;
  padding-top: 13px;
  padding-bottom: 10px;
  background-color: var(--button-background);
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.environment-dropdown-button-option.top {
  padding-top: 13px;
}

.environment-dropdown-button-option:hover .environment-icon-text-list,
.environment-dropdown-button-option:hover .environment-text-header-list,
.dropdown-button:hover .dropdown-icon,
.dropdown-button:hover span {
  color: var(--link-color);
  border-color: var(--link-color); 
}

.environment-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
}

.logout-button {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dark-mode-button:hover, .logout-button:hover, .observable-view-toggle-button:hover, .customer-view-button:hover, .environment-dropdown-button:hover, .tooltip-tutorial-button:hover {
  color: var(--link-color);
}

.logout-icon, .dark-mode-icon, .observable-view-icon, .customer-view-icon, .magnifying-glass-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  margin-left: 2px;
}

.App-logo {
  cursor: pointer;
  height: 23px;
  width: auto;
  margin-right: 15px;
  margin-left: 3px;
  object-fit: contain;
  transform: translateZ(0);
  display: flex;
  justify-content: center;
}

.App-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  gap: 5px;
}

.App-card {
  position: relative;
  width: 200px;
  border-radius: 7px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--card-border-color);
  box-sizing: border-box;
  font-size: .9em;
}

.First-two-cards {
  display: flex;
  flex-direction: row;
}

.App-card-first {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 10px;
  padding-left: 15px;
  width: var(--first-card-width);
  cursor: pointer;
  background-color: var(--background-color);
  font-size: .95em;
}

.App-card-first:hover::after {
  content: "Unfiltered Totals";
  position: absolute;
  z-index: 2000;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  text-align: left;
  padding: 10px;
  white-space: nowrap;
  transform: translateX(-15%);
  bottom: 100%;
  margin-bottom: 5px;
  border-radius: 2px;
}

/* Light mode styles */
:root:not(.dark-mode) .App-card-first:hover::after {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none;
}

/* Dark mode styles */
.dark-mode .App-card-first:hover::after {
  border: 1px solid white;
  box-shadow: none;
}

.App-card-first .row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
}

.App-card-first .number {
  font-size: 1.3em;
  min-width: 35px;
  display: flex;
  justify-content: center;
}

.App-card-first .text {
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-card-first .separator {
  width: 100%;
  height: 1px;
  background-color: var(--card-border-color);
  margin-top: 10px;
  margin-bottom: 10px;
}

.App-card-second {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: auto auto 1fr;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  justify-items: start;
  font-size: .75em;
  padding: 10px 20px;
  width: var(--second-card-width);
}

.App-card-second .icon {
  margin-top: 2px;
  margin-right: 3px;
  cursor: pointer;
}

.App-card-second .text {
  font-size: 1.1em;
  cursor: pointer;
}

.App-card-second .number {
  font-size: 1.1em;
  justify-self: end;
  cursor: pointer;
}

.App-card-third {
  position: relative;
  z-index: 900;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  overflow-y: hidden;
  cursor: pointer;
  padding: 10px;
  height: 85px;
  max-height: 85px;
  flex-grow: 1;
  transition: max-height 0.5s ease; 
}

.App-card-third.expanded {
  z-index: 900;
  position: absolute;
  background-color: var(--background-color);
  height: auto; 
  min-height: 85px;
  max-height: 30%; 
  overflow-y: auto;
  width: calc(100% - var(--first-two-cards-width) + 12px);
  left: calc(var(--first-two-cards-width) - 12px); 
  transform: scaleY(1);
}

.App-card-third h4 {
  z-index: 900;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: .8em;
}

.App-card-third h4 img {
  z-index: 900;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.report-content pre {
  z-index: 900;
  font-size: .9em;
  max-height: 200px;
  white-space: pre-wrap; 
  word-wrap: break-word; 
}

.App-filter-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.App-filter-row-workflows {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.App-filter-button {
  background-color: var(--button-background);
  border-radius: 2px;
  padding: 5px 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Asap', sans-serif;
  font-size: .75em;
  color: var(--text-color);
  height: 23px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-filter-button.active {
  background-color: var(--button-hover-background);
  border-radius: 2px 0 0 2px;
  margin-right: 3px;
}

.App-filter-button img {
  width: 14px;
  height: 9px;
  margin-right: 4px;
}

.App-filter-dropdown {
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 1px, 1px 1px, 1px var(--box-shadow-color);
}

.App-filter-clear {
  background-color: var(--button-hover-background);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: .7em;
  line-height: 1;
  height: 23px;
  border-radius: 0 2px 2px 0;
  margin-right: 5px;
}

.App-filter-clear-search {
  background-color: var(--button-hover-background);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: .6em;
  line-height: 1;
  height: 21px;
  border-radius: 0px 0px 0px 0px;
}

.dropdown-option {
  padding-top: 10px;
  padding-left: 8px;
  padding-bottom: 10px;
  font-size: .8em;
}

.dropdown-option-feedback {
  padding-top: 10px;
  padding-left: 8px;
  padding-bottom: 10px;
  font-size: .7em;
  min-width: 150px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-option-feedback:hover {
  background-color: var(--button-hover-background);
  width: 100%;
}

.dropdown-option-versions {
  font-size: .9em;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.dropdown-option-versions:hover {
  background-color: var(--button-hover-background);
}

.dropdown-option-versions input[type="checkbox"] {
  height: 10px;
  width: 10px;
}

.dropdown-option:hover {
  background-color: var(--button-hover-background);
  border-radius: 2px;
  cursor: pointer;
}

.App-search-submit {
  background-color: transparent;
  color: var(--text-color);
  border: none;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  font-size: .7em;
  height: 23px;
  transition: background-color 0.3s ease;
}

.App-search-submit:hover {
  background-color: var(--button-hover-background);
}

.styled-search-input {
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: .8em;
  padding-top: 3px;
  padding-bottom: 3px;
  transition: border-color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.styled-search-input:focus {
  border-color: var(--search-input-focus-border-color);
  outline: none;
}

.App-filter-options-workflows {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  z-index: 1000;
  min-width: 150px;
  max-height: 100px; 
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.App-filter-options-workflows-search {
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 0;
  background-color: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  z-index: 1000;
  min-width: 150px;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

/* Add styling for the scrollbar */
.App-filter-options-workflows::-webkit-scrollbar {
  width: 6px;
}

.App-filter-options-workflows::-webkit-scrollbar-track {
  background: var(--background-color);
}

.App-filter-options-workflows::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 3px;
}

.App-filter-options-workflows::-webkit-scrollbar-thumb:hover {
  background: var(--text-color);
}

.App-filter-options-workflows-versions {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  z-index: 1000;
  min-width: 150px;
  max-height: 110px;
  overflow-y: auto;
  scrollbar-width: none;  
  -ms-overflow-style: none; 
}

.App-filter-options-metrics {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  z-index: 1000;
  min-width: 150px;
  max-height: 200px;
  font-size: .9em;
  overflow-y: auto;
  scrollbar-width: none;  
  -ms-overflow-style: none; 
  display: flex;
  flex-direction: column;
}

.App-filter-option-metrics {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  min-width: 150px;
  width: auto;
}

.App-filter-option-metrics:hover {
  background-color: var(--button-hover-background);
}

.App-submit-versions {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background: var(--button-background);
  color: var(--text-color);
  border: none;
  font-family: 'Asap', sans-serif;
  font-size: .8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top: 1px solid var(--border-color);
}

.App-submit-versions:hover {
  background: var(--button-hover-background);
}

.App-date-range-picker-main { 
  border: 2px solid var(--border-color);
  border-radius: 2px;
  z-index: 950;
  width: 350px;
  position: absolute;
  top: 120px; 
  left: 90px;
  z-index: 1000; 
}

.App-date-range-picker-metrics { 
  border: 2px solid var(--border-color);
  border-radius: 2px;
  z-index: 950;
  width: 350px;
  position: absolute;
  top: 30px; 
  left: 265px;
  z-index: 1000; 
}

.App-date-range-picker-workflows { 
  border: 2px solid var(--border-color);
  border-radius: 2px;
  z-index: 950;
  width: 350px;
  position: absolute;
  bottom: 100%; 
  z-index: 1000; 
}

.App-submit-date-range-workflows {
  position: relative;
  width: 100%;
  padding: 5px;
  background: var(--calendar-navigation-background);
  color: var(--text-color-calendar);
  border: none;
  font-family: 'Asap', sans-serif;
  font-size: .8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-submit-date-range-metrics {
  width: 100%;
  padding: 5px;
  background: var(--calendar-navigation-background);
  color: var(--text-color-calendar);
  border: none;
  font-family: 'Asap', sans-serif;
  font-size: .8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-table-body.scrollable {
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0;
}

.App-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px);
}

.App-table::webkit-scrollbar {
  display: none;
}

.App-table-header {
  font-weight: bold;
  color: var(--text-color);
  background-color: var(--table-header-background);
  border-radius: 2px 2px 0px 0px;
  flex-shrink: 0;
}

.debug-icon-header, .user-icon-header {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.environment-icon-header-list {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  margin-left: 3px;
}

.environment-icon-text {
  position: relative;
  font-size: .8em !important; 
  color: var(--text-color);
  font-weight: bold;
  font-family: 'Tahoma', sans-serif;
  border: 1px solid var(--text-color);
  border-radius: 50%; 
  padding-left: 5px; 
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.environment-icon-text-list {
  position: relative;
  font-size: .8em !important; 
  color: var(--text-color);
  font-weight: bold;
  font-family: 'Tahoma', sans-serif;
  border: 1px solid var(--text-color);
  border-radius: 50%; 
  padding-left: 5px; 
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}


.environment-text-header-list {
  font-size: 1em !important; 
  font-weight: bold;
  font-family: 'Tahoma', sans-serif;
  margin-left: 5px;
}

.shrinked-image {
  height: 14px;
  border-radius: 50%;
}

.App-table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  font-size: .7em;
}

.App-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.App-table-cell { box-sizing: border-box; }
.App-table-cell:nth-child(1) { width: 7%; text-align: center; margin-left: 10px;}
.App-table-cell:nth-child(2) { width: 6%; text-align: center;}
.App-table-cell:nth-child(3) { width: 6%; text-align: center;}
.App-table-cell:nth-child(4) { width: 10%; }
.App-table-cell:nth-child(5) { width: 6%; text-align: center;}
.App-table-cell:nth-child(6) { width: 6%; text-align: center;}
.App-table-cell:nth-child(7) { width: 30%;}
.App-table-cell:nth-child(8) { width: 15%; }
.App-table-cell:nth-child(8) { width: 10%; }

.status-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

.status-icon-failed,
.status-icon-success,
.status-icon-error,
.status-icon-warning {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  border-radius: 50%;
}

.status-icon-failed {
  color: rgb(212, 0, 0);
}

.status-icon-success {
  color: #4caf50;
}

.status-icon-error,
.status-icon-warning {
  color: var(--status-icon-error-color);
}

.status-icon-card {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

.status-icon-card-failed,
.status-icon-card-success,
.status-icon-card-error {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-bottom: 3px;
  margin-right: 3px;
}

.status-icon-card-failed {
  color: rgb(212, 0, 0);
}

.status-icon-card-success {
  color: #4caf50;
}

.status-icon-card-error {
  color: var(--status-icon-error-color);
}

/* CALENDAR */
.custom-calendar {
  font-size: 0.8em;
  background: var(--calendar-background) !important;
  font-family: 'Asap', sans-serif;
  line-height: 1.125em;
  border-radius: 0px;
  border: none !important;
  box-shadow: none;
  z-index: 1000;
}

/* NAVIGATION */
.custom-calendar .react-calendar__navigation {
  height: 30px;
  background: var(--calendar-navigation-background);
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

/* NAVIGATION BUTTONS */
.custom-calendar .react-calendar__navigation button {
  min-width: 40px;
  height: 100%;
  background: none;
  color: var(--text-color-calendar);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  box-shadow: none;
}

/* NAVIGATION LABEL */
.custom-calendar .react-calendar__navigation__label {
  flex-grow: 0;
  font-weight: bold;
  background-color: var(--calendar-navigation-background) !important;
  color: var(--text-color-calendar) !important;
}

/* NAVIGATION BUTTONS */
.custom-calendar .react-calendar__navigation button:enabled:hover,
.custom-calendar .react-calendar__navigation button:enabled:focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--text-color-calendar);
}

/* WEEKDAYS (Mon, Tue, etc.) */
.custom-calendar .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none !important;
  font-size: 0.75em;
  background-color: var(--table-header-background);
  border-bottom: none !important;
}

/* INDIVIDUAL WEEKDAYS */
.custom-calendar .react-calendar__month-view__days__day {
  color: var(--calendar-text-color);
  border: none;
  box-shadow: none;
}

/* DATE BOX */
.custom-calendar .react-calendar__tile {
  max-width: 100%;
  padding: 3px;
  background-color: var(--background-color);
  text-align: center;
  line-height: 2em;
  font-size: .9em;
  border: none;
  box-shadow: none;
}

/* HOVER DATE CURSOR */
.custom-calendar .react-calendar__tile:enabled:hover,
.custom-calendar .react-calendar__tile:enabled:focus {
  background-color: var(--calendar-tile-hover-background);
  border-radius: 0px;
}

/* NOW DATE */
.custom-calendar .react-calendar__tile--now {
  background-color: var(--calendar-tile-now-background);
}

/* ACTIVE DATE */
.custom-calendar .react-calendar__tile--active {
  background: var(--calendar-tile-active-background);
  color: var(--tooltip-text-color);
  border-radius: 0px;
}

/* ACTIVE DATE AFTER CLICK */
.custom-calendar .react-calendar__tile--active:enabled:hover,
.custom-calendar .react-calendar__tile--active:enabled:focus {
  background-color: var(--calendar-tile-active-background);
  border-radius: 0px;
}

/* HOVER DATE FOR RANGE */
.custom-calendar .react-calendar__tile--hover {
  color: var(--text-color-calendar);
  background-color: var(--calendar-tile-active-background) !important;
}

/* NEIGHBORING MONTHS */
.custom-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--calendar-neighboring-month-color);
}

/* YEAR VIEW, DECADE VIEW, CENTURY VIEW */
.custom-calendar .react-calendar__year-view__months__month,
.custom-calendar .react-calendar__decade-view__years__year,
.custom-calendar .react-calendar__century-view__decades__decade {
  height: 20px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
} 

/* SUBMIT DATE RANGE/ CLOSE BUTTON */
.App-submit-date-range {
  width: 100%;
  padding: 5px;
  background: var(--calendar-navigation-background);
  color: var(--text-color-calendar);
  border: none;
  font-family: 'Asap', sans-serif;
  font-size: .8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-submit-date-range:hover {
  background: var(--button-hover-background); 
}

.App-submit-date-range-main {
  width: 100%;
  padding: 5px;
  background: var(--calendar-navigation-background);
  color: var(--text-color-calendar);
  border: none;
  font-family: 'Asap', sans-serif;
  font-size: .8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-submit-date-range-main:hover {
  background: var(--button-hover-background); 
}

.App-pagination {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: var(--background-color);
  border: 1px solid var(--box-shadow-color);
  font-size: .9em;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.App-pagination.drawer-open {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-200px);
}

.App-pagination button {
  background: var(--background-color);
  border: none;
  cursor: pointer;
}

.App-pagination span {
  font-family: 'Asap', sans-serif;
  color: var(--text-color);
}

.pagination-arrow {
  color: var(--text-color);
  opacity: 0.5;
}

.page-input {
  width: 20px;
  text-align: center;
  padding: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--button-background);
  color: var(--text-color);
  border-radius: 2px;
  outline: none;
  box-shadow: 0 0 5px var(--box-shadow-color);
  transition: border-color 0.3s;
}

.page-input:focus {
  border-color: var(--link-color);
}

.page-input:blur {
  border-color: var(--border-color);
}

.WorkflowDetails {
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.WorkflowDetails .App-header {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 17px;
  padding-right: 20px;
  border-bottom: 1px solid var(--header-border-bottom); 
}

.WorkflowDetails .App-header-right {
  padding-right: 35px;
}

.node-slide-out {
  animation: slideOut 1s forwards;
}

.node-slide-in {
  animation: slideIn 1s forwards;
}

.fixed-top {
  position: absolute;
  left: 0;
  z-index: 10;
  margin-left: 10px;
  margin-top: 50px;
  background-color: var(--background-color);
  border-radius: 2px;
  width: fit-content;
  overflow: visible;
  transition: height 0.3s ease;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center; 
}

.popup-drawer {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 55px;
  margin-right: 20px;
  background-color: var(--background-color);
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  border-radius: 2px;
  width: fit-content;
  overflow: visible;
  transition: height 0.3s ease;
  padding: .7em;
  display: flex;
  align-items: center; 
  font-size: .9em;
  cursor: pointer;
}

.left-drawer {
  position: absolute;
  left: -250px; 
  z-index: 900;
  margin-top: 85px;
  background-color: var(--background-color);
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  border-radius: 2px;
  width: 190px; 
  padding-left: 25px;
  padding-right: 10px;
  transition: left 0.3s ease; 
  overflow-y: auto;
}

.left-drawer.open {
  left: -10px; 
  transition: left 0.3s ease;
}

.left-drawer::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
  background-color: transparent;
}
  
.left-drawer::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.drawer-toggle-button {
  position: fixed;
  top: 85px; 
  left: 15px; 
  z-index: 900; 
  background-color: var(--background-color);
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  color: var(--text-color);
  border: none;
  padding: .8em;
  cursor: pointer;
  border-radius: 2px;
  font-size: .65em;
}

.observable-list {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin-top: 3px;
}

.speech-bubble {
  font-size: .7em;
  color: var(--background-color);
  border-radius: 2px;
  padding: 8px;
  position: relative;
  background-color: var(--message-background-color);
  cursor: pointer;
  margin-bottom: 10px;
  min-height: 14px;
}

.speech-bubble::before {
  content: '';
  position: absolute;
  left: -7px; /* Adjust to position the tail */
  top: 20px; /* Adjust to position the tail vertically */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 10px solid var(--message-background-color); /* Match the background color of the bubble */
}

.speech-bubble:hover,
.selected .speech-bubble {
  color: var(--message-text-color); 
  background-color: var(--message-background-hover-color);
}

.speech-bubble:hover::before,
.selected .speech-bubble::before {
  color: var(--message-text-color); 
  border-right-color: var(--message-background-hover-color);
}

.observable-item {
  cursor: pointer;
}

.node-title {
  display: flex;
  align-items: center;
  font-size: .5em;
  margin-bottom: 7px;
  height: 1px;
}

.left-drawer-node-icon {
  margin-right: 1px;
  height: 1.1em;
}

.left-drawer-node-icon-agent {
  margin-right: 4px;
  height: 1em;
}

.start-message-item {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.start-message {
  display: flex;
  justify-content: flex-end;
}

.start-bubble {
  position: relative;
  right: 0;
  top: 0; 
  background-color: var(--message-background-color);
  border-radius: 2px;
  padding: 6px;
  color: var(--background-color);
  font-size: .7em;
  margin-bottom: 10px;
  width: fit-content;
  min-width: 50px;
}

.start-tail {
  content: '';
  position: absolute;
  left: 94%; 
  top: 58%; 
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 0px solid transparent;
  border-left: 10px solid var(--message-background-color); 
}

.App-filter-row-customer-env {
  margin: 0px;
  padding: 0px;
}

.env-switcher-button {
  background-color: var(--button-background);
  border-radius: 2px;
  padding: 6px 8px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
  font-family: 'Asap', sans-serif;
  font-size: .7em;
  color: var(--text-color);
}

.env-switcher-icon {
  margin-right: 8px;
  height: .8em;
}

.dev-text {
  font-weight: 600;
  color: #00a9a9;
}

.prod-text {
  font-weight: 600;
  color: #cc0000;
}

.right-angles {
  cursor: pointer;
  color: var(--text-color);
  font-size: .7em;
}

.version-text {
  font-size: .6em;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.popup-drawer-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.left-drawer-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  margin-top: 5px;
  width: 100%;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--text-color);
  position: absolute;
  top: 20px;
  right: 20px;
}

.close-button-right-drawer {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--text-color);
  position: absolute;
  right: 20px;
}

.close-button-left-drawer {
  position: relative; 
  font-size: 1.2em;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  color: var(--text-color);
  background: none;
  border: none;
}

.close-button:hover {
  color: var(--text-color);
  opacity: 0.7;
}

.drawer {
  position: fixed;
  right: -380px; 
  width: 350px;
  height: calc(101vh - 45px);
  background-color: var(--background-color);
  box-shadow: -1px 0px 1px 1px var(--box-shadow-color); 
  transition: right 0.3s ease;
  z-index: 900;
  margin-top: 43px;
}

.drawer.open {
  margin-top: 45px;
  right: 0; 
  margin-left: 20px;
}

.fixed-bottom {
  height: auto;
  position: fixed;
  bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  background-color: transparent !important;
  border: none;
  font-size: .9em;
  transition: margin-right 0.3s ease;
  z-index: 900;  
}

.fixed-bottom.open {
  background-color: transparent;
  margin-right: 360px;
  right: 0;
}

.breadcrumbs {
  font-size: 0.7em;
  color: var(--text-color);
  display: flex; /* Use flexbox for horizontal layout */
  gap: 5px; /* Space between breadcrumb items */
}

.breadcrumbs span {
  cursor: pointer;
}

.breadcrumbs span:hover {
  text-decoration: underline;
}

.current-breadcrumb {
  color: var(--current-breadcrumb-color);
  text-decoration: underline;
  cursor: pointer;
}

.not-current-breadcrumb {
  cursor: pointer;
}

.back-button {
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color);
  font-size: .7em;
}

.number-of-runs {
  margin-left: 8px;
  justify-content: space-between;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 5px;
}

.key-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 1em;
  color: var(--text-color);
}

.workflow-table {
  /* width: 100% !important; */
  border: none;
  border-collapse: collapse;
}

.workflow-table td {
  cursor: pointer;
}

.workflow-table th {
  position: sticky;
  font-weight: bold;
  background-color: var(--table-header-background);
  color: var(--text-color);
  top: 0;
  z-index: 10;
}

.workflow-table th.sortable {
  cursor: pointer;
}

.workflow-table th,
.workflow-table td {
  width: 100%;
  padding: 6px 8px;
  text-align: left;
  font-size: .9em;
  white-space: nowrap; 
}

.workflow-table tbody tr:hover {
  background-color: var(--table-row-hover-background);
}

.sort-icon {
  width: .8em;
  height: .8em;
  margin-left: 5px;
  color: var(--text-color);
  display: inline-block;
  transition: transform 0.2s ease;
}

.spinning-sort {
  animation: spin-sort 0.2s linear;
}

@keyframes spin-sort {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.table-container {
  position: relative;
  height: 15vh; 
  overflow: hidden;
}

.resizer-icon {
  position: absolute;
  cursor: ns-resize;
  background-color: var(--table-header-background);
  width: 21px;
  border-radius: 2px;
  font-size: 1em;
  left: 50%;
  bottom: -6px;  
  transform: translateX(-50%) scaleX(2); 
}

.table-body-container {
  height: calc(100%); 
  width: calc(100%);
  overflow-y: scroll;
  border-radius: 2px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: .9em;
}

.table-body-container::-webkit-scrollbar {
  width: 0px; 
  background-color: transparent;
}
  
.table-body-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.workflow-table th:nth-child(1), .workflow-table td:nth-child(1) { width: .1%; }
.workflow-table th:nth-child(2), .workflow-table td:nth-child(2) { width: 2%; }
.workflow-table th:nth-child(3), .workflow-table td:nth-child(3) { width: 2%; min-width: 130px; }
.workflow-table th:nth-child(4), .workflow-table td:nth-child(4) { width: 2%; }
.workflow-table th:nth-child(5), .workflow-table td:nth-child(5) { width: 2%; min-width: 150px; }
.workflow-table th:nth-child(6), .workflow-table td:nth-child(6) { width: 5%; }
.workflow-table th:nth-child(7), .workflow-table td:nth-child(7) { width: 25%; }

.status-icon[alt="Running"] {
  animation: spin 1s linear infinite;
}

.light-opacity {
  opacity: 0.5;
  color: inherit;
}

.report-date {
  padding-left: 5px;
  float: right;
  font-size: 0.9em;
  color: var(--text-color);
  font-weight: normal;
}

.report-date-separator {
  margin-left: 5px;
  font-size: 1em;
  color: var(--text-color);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 1.4em;
  font-weight: 600;
}

.popup-content {
  border-radius: 2px;
  padding: 0 15px 15px 15px;
  width: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border-color);
  min-height: 450px;
}

.popup-header-section {
  flex-shrink: 0; /* Prevents header from shrinking */
}

.popup-scrollable-content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.popup-scrollable-content {
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 8px; 
}

.popup-content p {
  margin-bottom: 0 !important;
}

.node-icon-popup {
  margin-right: 10px;
}

.main-row {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.popup-content .title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-size: 1.2em;
  width: 100%;
}

.title-header {
  font-weight: 600;
  font-size: .9em;
}

.popup-content p {
  line-height: 1.5;
  font-size: 1em;
}

.popup-content strong {
  color: var(--text-color);
  font-weight: 600;
}

.popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-track {
  background: var(--background-color);
}

.popup-content::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 2px;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: var(--text-color);
}

.login-container {
  background-color: var(--login-container-background);
  height: 400px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 2px;
  box-shadow: 0 2px 2px var(--box-shadow-color);
  text-align: center;
  backdrop-filter: blur(5px);
}

.login-page {
  background-color: var(--login-background);
  color: var(--login-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: 400% 400%;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--login-button-background);
  color: var(--login-button-text-color);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: var(--login-button-background-hover);
}

.company-logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 150px; /* Ensure height is set */
  background-image: var(--login-icon-image);
}

.welcome-message {
  color: var(--login-text-color);
  font-size: 1.4em;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}

.welcome-subtitle {
  color: var(--login-text-color);
  font-size: 1.2em;
  margin-bottom: 1.5em;
}

.selected-run {
  background-color: var(--table-row-hover-background);
}

.formatted-content  {
  font-size: .7em;
  border-radius: 2px;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0 !important;
}

.formatted-content-empty  {
  border-radius: 2px;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0 !important;
}

.formatted-content-empty  {
  border-radius: 2px;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0 !important;
}


.description-content {
  font-size: .6em;
}

.description-content-regular {
  font-size: .65em;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--text-color) !important;
}

.description-text-content {
  padding-top: 10px;
  font-size: .8em;
  color: var(--text-color);
  padding-bottom: 5px;
  margin-top: 5px;
}

.handle-id-click {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .25;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.error-notification {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--error-background);
  color: var(--error-text-color);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  border-radius: 2px;
  padding: 0;
}

.error-content {
  text-align: center;
  background-color: var(--error-background);
  color: var(--error-text-color);
  padding: 2rem;
  border-radius: 2px;
  max-width: 400px;
}

.error-content-actual {
  text-align: center;
  background-color: var(--error-background);
  color: var(--error-text-color);
  border-radius: 2px;
  max-width: 400px;
  padding-right: 50px;
  padding-top: 50px;
}

.error-content h2 {
  color: var(--error-text-color);
  margin-bottom: 1em;
}

.error-message {
  color: var(--error-text-color);
  margin-bottom: 1em;
  margin-top: 1.5em;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.retry-button {
  background-color: var(--link-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: var(--link-hover-color);
}

.loading-spinner-corner {
  position: absolute;
  top: 90px;
  right: 0px;
  z-index: 500;
}

.loading-spinner-corner-workflow {
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 1500;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid var(--spinner-border-color);
  border-top: 3px solid var(--spinner-border-top-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-spinner-workflow {
  width: 20px;
  height: 20px;
  border: 3px solid var(--spinner-border-color);
  border-top: 3px solid var(--spinner-border-top-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  right: 0;
  margin-bottom: 60px;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.App-search-input-container {
  border-top: 1px solid var(--search-input-border-color);
  border-right: 1px solid var(--search-input-border-color);
  border-bottom: 1px solid var(--search-input-border-color);
  border-radius: 2px;
  margin-left: -1px;
  display: flex;
  align-items: center;
}

.styled-search-input-workflow {
  border: none;
  border-radius: 0 2px 2px 0;
  padding: 4px;
  box-sizing: border-box;
  font-size: .8em;
  height: 21px;
  transition: border-color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.styled-search-input-workflow:focus {
  border-color: var(--search-input-focus-border-color);
  outline: none;
}

.App-filter-button-search {
  background-color: var(--button-background);
  border-radius: 2px 0 0 2px;
  padding: 5px 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Asap', sans-serif;
  font-size: .75em;
  color: var(--text-color);
  height: 23px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.key-value-pair {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-size: .65em;
}

.key-value-pair strong {
  flex: 1;
  text-align: left;
  font-size: .65em;
}

.key-value-pair span {
  flex: 1;
  text-align: right;
  font-size: .65em;
}

.key-formatted {
  font-weight: bold;
  margin: 0 !important;
  text-decoration: underline;
}

.output-row {
  padding: 8px 6px; /* Combined padding for top/bottom and left/right */
  background-color: var(--output-row-background);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Ensures no gap between elements */
  margin-bottom: 10px;
  overflow-wrap: break-word; /* Prevents horizontal scrolling */
}

.output-row p {
  font-size: .7em !important;
  margin: 0; /* Remove default paragraph margins */
  overflow-wrap: break-word; /* Prevents horizontal scrolling */
}

.key-formatted {
  font-size: .8em !important;
  font-weight: bold;
  margin: 0 !important;
  text-decoration: none;
}

.id-tooltip {
  cursor: pointer;
  position: relative;
}

.id-tooltip:hover::after {
  content: attr(title);
  position: absolute;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  padding: 8px; 
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s, background-color 0.3s;
  pointer-events: none;
  top: 100%;
  left: 20%;
  transform: translateX(-50%);
  opacity: 1;
}

:root:not(.dark-mode) .id-tooltip::after {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none;
}

.dark-mode .id-tooltip::after {
  border: 1px solid white;
  box-shadow: none;
}

.copied-style {
  position: absolute;
  top: -20px;
  left: 100%;
  transform: translateX(-100%);
  background-color: var(--copied-background-color);
  padding: 2px 5px;
  border-radius: 2px;
  font-size: .7em;
  color: var(--copied-text-color);
}

.copy-icon-container {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

.copy-icon {
  margin-right: 5px;
}

.divider-popup {
  border: none;
  border-top: 2px solid var(--divider-color);
  margin: 10px 0;
}

.divider-popup-output {
  border: none;
  background-color: transparent;
  margin: 3px 0;
}

.previous-version {
  background-color: var(--previous-version-background);
  opacity: 0.5;
}

.tiny-checkbox {
  position: fixed;
  margin-left: 0px;
  transform: scale(0.83);
  height: .8em;
  margin-top: 2px;
  vertical-align: middle;
  border: 1px solid var(--tiny-checkbox-border-color);
  background-color: var(--background-color);
  margin-left: 5px;
  cursor: pointer;
}

/* Light mode styles */
:root:not(.dark-mode) .tiny-checkbox:hover::after,
:root:not(.dark-mode) .tiny-checkbox:checked:hover::after {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none;
}

/* Dark mode styles */
.dark-mode .tiny-checkbox:hover::after,
.dark-mode .tiny-checkbox:checked:hover::after {
  border: 1px solid white;
  box-shadow: none;
}

@keyframes spin-sort {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-hover-color);
}

.observable-item:focus {
  outline: none; 
}

.node-label {
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  color: var(--text-color-node);
  font-size: .7em;
  position: relative; /* Ensure the node can be positioned absolutely */
  transition: transform 0.3s, opacity 0.3s; /* Smooth transition */
}

.node-icon {
  height: 9px;
  margin-right: 5px;
}

.node-dropdown-icon {
  cursor: pointer;
  height: 10px;
  margin: 0;
  transition: transform 0.3s ease;
}

.node-dropdown-icon.rotated {
  transform: rotate(180deg);
}

.selected-node {
  border: 2px solid var(--selected-node-border-color) !important; 
  border-radius: 2px; 
}

.workflow-container {
  height: 300%;
  width: 100%;
  background: var(--background-color);
  color: var(--text-color);
}

.details-cell {
  cursor: default; 
  overflow: hidden;
}

.controls-style {
  position: fixed;
  bottom: 500px;
  z-index: 1100;
  background-color: transparent;
  fill: var(--text-color);
  border: none !important; 
  box-shadow: none !important;
}

.react-flow__node {
  cursor: pointer !important;
}

.error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
} 

.summary-with-images {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.summary-text {
  flex: 1;
  min-width: 0; /* Allows text to wrap properly */
}

.summary-images {
  flex: 0 0 auto; /* Prevents images from growing/shrinking */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  padding-top: 15px;
  margin-left: auto; /* Pushes images to the right */
}

.summary-thumbnail {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  object-fit: cover; /* Maintains aspect ratio while filling the space */
}

.small-chevron {
  font-size: 0.8em; /* Adjust the size as needed */
  margin-left: 5px;
  cursor: pointer;
}

.small-chevron-versions {
  font-size: 0.7em; /* Adjust the size as needed */
  cursor: pointer;
}

.see-more-button {
  background-color: transparent;
  color: var(--text-color-see-more);
  border: none;
  cursor: pointer;
  font-size: .8em;
}

.see-more-button:hover {
  color: var(--link-hover-color-see-more);
  text-decoration: underline;
}

.output-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.output-popup-content {
  display: flex;
  position: relative;
  background-color: var(--popup-background);
  color: var(--text-color);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  border-radius: 2px;
  max-height: 500px;
  max-width: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.output-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.output-popup-title {
  font-size: 1.3em;
  color: var(--text-color);
}

.output-close-button {
  color: var(--text-color);
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.output-popup-content ul {
  list-style-type: none;
  padding: 0;
}

.output-popup-content li {
  margin-bottom: 10px;
}

.error-popup-list {
  font-size: .9em;
  line-height: 1.5em;
}

.output-content {
  width: 100%;
  overflow-y: auto; 
  padding-left: 20px;
}

.output-content::-webkit-scrollbar {
  display: none;
}

.output-navigation {
  width: 30px; 
  overflow: hidden; 
  position: relative;
  display: flex;
  align-items: center;
  top: 0; 
  padding-right: 10px;
  padding-left: 5px;
}

.output-navigation button {
  font-size: .7em;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--link-color); 
}

.output-navigation button:hover {
  text-decoration: underline;
}

.inactivity-warning {
  background-color: var(--button-background);
  color: var(--text-color);
  font-size: 1em;
  padding: 15px 20px;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2000;
  text-align: center;
  max-width: 300px;
  width: 80%;
}

.node-key-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1; 
  margin-right: 5px;
}

.node-key {
  display: flex;
  align-items: center;
}

.node-key-item {
  font-size: .9em;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.node-key-item .fa-icon {
  margin-right: 5px;
}

.not-authorized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color, #ff3636);
  font-family: 'Asap', sans-serif;
}

.not-authorized-container h1 {
  color: #a40000;
  font-size: 2.5em;
  margin-bottom: 1rem;
}

.not-authorized-container p {
  font-size: 1.25em;
  max-width: 500px;
}

.authentication-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  font-family: 'Asap', sans-serif;
  background-color: var(--background-color, #f0f0f0);
}

.authentication-container h1 {
  color: var(--text-color);
  font-size: 2.5em;
  text-align: center;
}

.authentication-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid var(--spinner-border-color);
  border-top: 3px solid var(--spinner-border-top-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.authentication-logo {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.back-to-main-button {
  background-color: var(--button-background, #007bff); 
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.back-to-main-button:hover {
  color: var(--background-color);
  background-color: var(--link-hover-color, #0056b3);
}

.image-modal {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  padding: 20px;
  isolation: isolate; 
}

.image-modal-overlay img {
  max-width: 70vw;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 2px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
  position: relative; 
  z-index: 1;
}

.top-right-filter-button {
  position: fixed;
  top: 55px;
  right: 55px; 
  transition: right 0.3s ease; 
  z-index: 900; 
  background-color: var(--background-color);
  box-shadow: 1px 1px 1px 1px var(--box-shadow-color);
  color: var(--text-color);
  border: none;
  padding: .65em;
  cursor: pointer;
  border-radius: 2px;
  font-size: .78em;
}

.top-right-filter-button.drawer-open {
  right: 360px; 
}

.top-right-filter-button:hover {
  background-color: var(--button-hover-background, #e6e6e6);
}

.workflow-link {
  color: var(--link-color); /* Use the link color variable */
  text-decoration: underline;
  cursor: pointer;
}

.workflow-link:hover {
  color: var(--link-hover-color); /* Use the link hover color variable */
}

.supervisor-report-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip-tutorial {
  position: fixed;
  background-color: var(--tooltip-background-tutorial); 
  color: var(--text-color-tutorial);
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--tooltip-background-tutorial);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .7em;
  text-align: center;
}

.tooltip-tutorial::after {
  content: '';
  position: absolute;
  bottom: -20px; 
  left: 50%;
  transform: translateX(-70%);
  border-width: 10px;
  border-style: solid;
  color: var(--text-color-tutorial);
  border-color: var(--tooltip-background-tutorial) transparent transparent transparent; 
}

.tooltip-0 {
  top: 2%;
  font-size: 1em;
  text-align: left;
  line-height: 1.5em;
  max-width: 250px;
}

.tooltip-1 {
  top: 2%;
  max-width: 250px;
  margin-left: 45vw;
  font-size: .7em;
  line-height: 1.5em;
}

.tooltip-2 {
  top: 20%;
  max-width: 250px;
  left: 40%;
  text-align: center;
  font-size: .7em;
  line-height: 1.5em;
}

.tooltip-0-workflows {
  top: 5%;
  left: 1%;
  max-width: 270px;
}

.tooltip-1-workflows {
  top: 20%;
  left: 40%;
  max-width: 250px;
} 

.tooltip-2-workflows {
  bottom: 15%;
  left: 30%;
  max-width: 250px;
} 

.tooltip-3-workflows {
  top: 5%;
  right: 1%;
  max-width: 300px;
  text-align: left;
} 

.close-tooltip-button-main {
  position: fixed;
  top: 15%;
  right: 2%;
  background-color: var(--tooltip-background-tutorial);
  color: var(--text-color-tutorial); 
  border: 2px solid var(--tooltip-background-tutorial); 
  border-radius: 50%; 
  padding: 5px;
  cursor: pointer;
  font-weight: bold; 
  font-size: 0.9em; 
  z-index: 1000;
  width: 30px; 
  height: 30px; 
}

.close-tooltip-button-workflows {
  position: fixed;
  top: 90%;
  right: 2%;
  background-color: var(--tooltip-background-tutorial);
  color: var(--text-color-tutorial); 
  border: 2px solid var(--tooltip-background-tutorial); 
  border-radius: 50%; 
  padding: 5px;
  cursor: pointer;
  font-weight: bold; 
  font-size: 0.8em; 
  z-index: 1000;
  width: 30px; 
  height: 30px; 
}

.tooltip-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.metrics-icon {
  color: white;
  font-size: .8em;
  cursor: pointer;
  margin-left: 8px;
}

.metrics-link {
  text-decoration: none !important;
  cursor: pointer;
}

.metrics-link.active {
  text-decoration: underline !important;
}

.metrics-text {
  color: var(--text-color);
  font-size: .9em;
  cursor: pointer;
  margin-left: 15px;
  padding-top: 3px;
  text-decoration: none !important;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
  gap: 20px;
  z-index: 2;
}

.chart-container::-webkit-scrollbar {
  width: 8px;
}

.chart-container::-webkit-scrollbar-track {
  margin-top: 50px;

  background: transparent;
}

.chart-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.chart-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.App-metrics {
  background-color: var(--background-color);
  position: fixed;
  top: 50px; 
  left: 10px;
  right: 10px;
  bottom: 10px; 
  overflow-y: hidden;
}

.App-metrics-wrapper {
  position: fixed;
  width: calc(100% - 40px);
  height: 100%;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

.App-metrics-row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  justify-content: space-between;
}

.chart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 10;
  pointer-events: auto;  
  font-family: 'Asap', sans-serif;
  font-size: 0.9em;
  text-align: center;
}

.table-container-metrics {
  width: 400px;
  font-size: 0.8em;
}

.table-container-metrics table {
    width: 100%;
    border-collapse: collapse;
}

.table-container-metrics td, .table-container-metrics th {
  padding: 8px;
  border: 1px solid #ccc;
}

.table-container-metrics th:first-child, .table-container-metrics td:first-child {
    width: auto;
    max-width: 10%;
    white-space: nowrap;
}

.table-container-metrics th:last-child, .table-container-metrics td:last-child {
    width: auto;
}

.workflow-group {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  overflow-x: hidden;
  margin-left: 30px;
  margin-right: 30px;
}

.workflow-header {
  width: 100%;
  text-align: left;
  font-size: 1em;
  margin-bottom: 0px;
  margin-top: 10px;
}

.workflow-divider {
  width: 100%;
  border: none;
  border-top: 1px solid var(--divider-color);
  margin-top: 10px;
}

.title-element {
  font-size: 14px;
  margin-bottom: 10px;
  font-family: 'Asap', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container {
  display: flex;
  margin-top: 5px;
}

.echarts-toolbox-icon {
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  border-radius: 2px;
  font-size: .8em;
  font-family: 'Asap', sans-serif;
  border: none;
  margin-bottom: 5px;
}

.tooltip-echarts {
  background-color: var(--popover-background-color);
  color: var(--popover-text-color);
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  border-radius: 3px;
  font-size: .8em;
  z-index: 10;
}

.loading-spinner-metrics {
  width: 25px; /* Slightly larger */
  height: 25px;
  border: 3px solid var(--spinner-border-color);
  border-top: 3px solid var(--spinner-border-top-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.App-metrics-filters-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-metrics-filters-right {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  right: 25px;
}

.contact-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.contact-popup {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 450px;
  max-width: 90vw;
  max-height: 90vh;
  box-sizing: border-box; 
}

.contact-popup h3 {
  margin-top: 0px;
}

.contact-info {
  margin-bottom: 15px;
  font-size: 0.8em;
  color: var(--text-color);
}

.contact-textarea {
  width: calc(100% - 20px); 
  box-sizing: border-box;
  min-height: 200px;
  max-height: 400px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
  resize: vertical;
  width: 100%;
}

.contact-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; 
}

.contact-button {
  background-color: var(--link-color);
  color: var(--text-color-button);
  border: none;
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.contact-button.close {
  color: var(--text-color);
  background-color: var(--button-background); 
}

.contact-button:hover {
  background-color: var(--link-hover-color);
}

.contact-button.close:hover {
  background-color: var(--button-hover-background);
}

.contact-button:disabled {
  background-color: var(--button-background);
  color: var(--text-color);
  cursor: not-allowed;
  opacity: 0.5;
}

.contact-text {
  color: white;
  font-size: 1em;
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none !important;
}

.notification {
  padding: 12px;
  font-size: 0.9em;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.character-count {
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.6;
  margin-top: 0px;
}

.contact-attachment-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.attachment-icon {
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.7;
  transition: opacity 0.3s;
  font-size: .7em;
}

.attachment-icon:hover {
  opacity: 1;
}

.attachment-input {
  display: none;
}

.attachment-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--button-background);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.remove-attachment {
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.7;
  transition: opacity 0.3s;
}

.remove-attachment:hover {
  opacity: 1;
}

.customer-dropdown-menu {
  position: absolute;
  right: 100%;
  top: 15%;
  background-color: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.customer-select-container {
  width: 100%;
}

.customer-selection-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.environment-highlight {
  color: var(--link-color) !important;
  border-color: var(--link-color) !important;
}

.environment-dropdown-button-option.environment-highlight {
  color: var(--link-color) !important;
}

/* Styling for all scrollbars in metrics components */
.App-metrics::-webkit-scrollbar,
.chart-container::-webkit-scrollbar{
  width: 8px;
}

.App-metrics::-webkit-scrollbar-thumb,
.chart-container::-webkit-scrollbar-thumb{
  background: var(--border-color);
  border-radius: 4px;
}

.App-metrics::-webkit-scrollbar-track,
.chart-container::-webkit-scrollbar-track{
  background: transparent !important;
}

.App-metrics::-webkit-scrollbar-thumb:hover,
.chart-container::-webkit-scrollbar-thumb:hover {
  background: var(--border-color);
}

.table-container-metrics::-webkit-scrollbar {
  width: 0px;
}

.table-container-metrics::-webkit-scrollbar-thumb {
  background: transparent;
}

.table-container-metrics::-webkit-scrollbar-track {
  background: transparent;
}

.table-container-metrics::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.no-data-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  color: #008f0e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 10;
  pointer-events: auto;  
  font-family: 'Asap', sans-serif;
  font-size: 0.9em;
  text-align: center;
  z-index: 1000;
}

.drawer-tabs {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  position: relative; 
  width: 100%;        
  display: flex;
  align-items: center;
}

.drawer-tab {
  padding: 4px 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: .8em;
  color: var(--text-color);
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease;
  display: flex;          
  align-items: center;    
  align-content: center;
  justify-content: space-between;  
}

.drawer-tab.active {
  border-bottom: 2px solid var(--link-color);
  color: var(--link-color);
}

.drawer-tab:hover {
  color: var(--link-hover-color);
}

.add-feedback-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 5px;  
  padding-left: 0px;
  font-size: 0.8em;
  border-radius: 50px;
}

.add-feedback-button:hover {
  color: var(--link-color);
}

.feedback-content {
  padding: 0;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.status-bubble {
  background-color: var(--button-background);
  color: var(--text-color);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  min-width: 15px;
  text-align: center;
  cursor: pointer;
}

.feedback-form {
  margin-top: 15px;
  margin-bottom: 15px;
  animation: slideDown 0.3s ease-out;
}

.feedback-textarea {
  width: 100%;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
  resize: vertical;
  font-family: inherit;
  box-sizing: border-box;
  margin-bottom: 2px;
}

.feedback-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedback-thumbs {
  display: flex;
  gap: 8px;
  margin-left: 5px;
}

.thumb-icon {
  cursor: pointer;
  font-size: 1.2em;
  opacity: 0.5;
  transition: all 0.2s ease;
}

.thumb-icon:hover {
  opacity: 0.8;
}

.thumb-icon.selected-up {
  color: var(--thumb-up-color);
  opacity: 1;
}

.thumb-icon.selected-down {
  color: var(--thumb-down-color);
  opacity: 1;
}

.feedback-submit, .feedback-cancel {
  padding: 5px 10px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
}

.feedback-submit {
  background-color: var(--link-color);
  color: white;
}

.feedback-cancel {
  background-color: var(--button-background);
  color: var(--text-color);
}

.feedback-submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feedback-list {
  margin-top: 10px;
}

.feedback-text-container {
  background-color: var(--button-background);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.feedback-item {
  margin-bottom: 10px;
}

.feedback-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.feedback-header-left {
  display: flex;
  align-items: center;
}

.feedback-header-right {
  text-align: right;
}

.feedback-timestamp {
  font-size: 0.6em !important;
  color: var(--text-color);
  opacity: 0.7;
}

.feedback-text {
  margin: 0;
  font-size: 0.8em !important;
  color: var(--text-color);
  white-space: pre-wrap;
}

.thumb-icon-inner {
  height: .8em;
  margin-left: 5px;
}

.thumb-icon-inner.selected-up {
  color: var(--thumb-up-color);
  opacity: 1;
}

.thumb-icon-inner.selected-down {
  color: var(--thumb-down-color);
  opacity: 1;
}

.feedback-text-container {
  background-color: var(--button-background);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}

.feedback-actions-bottom {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-left: auto;
}

.feedback-action-icon {
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.6;
  font-size: 0.8em;
}

.feedback-action-icon:hover {
  opacity: 1;
}

.delete-confirmation-popup {
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 8px auto; /* Center horizontally with auto margins */
  width: 90%; /* Take up most of the width while respecting max-width */
  position: relative; /* Add positioning context */
  left: 50%; /* Move right by 50% of parent width */
  transform: translateX(-50%); /* Pull back by 50% of element width */
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.delete-confirmation-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.2s ease;
}

.delete-confirmation-buttons button:first-child {
  background-color: #e74c3c;
  color: white;
}

.delete-confirmation-buttons button:first-child:hover {
  background-color: #c0392b;
}

.delete-confirmation-buttons button:last-child {
  background-color: var(--button-background);
  color: var(--text-color);
}

.delete-confirmation-buttons button:last-child:hover {
  background-color: var(--button-hover-background);
}

.status-bubble[data-status="New"] {
  background-color: var(--status-new-color);
}

.status-bubble[data-status="Needs Review"] {
  background-color: var(--status-needs-review-color);
}

.status-bubble[data-status="Reviewed"] {
  background-color: var(--status-reviewed-color);
}

.status-bubble[data-status="Addressed"] {
  background-color: var(--status-addressed-color);
}

.status-bubble[data-status="Archived"] {
  background-color: var(--status-archived-color);
}

.search-container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  margin-right: 5px;
  width: auto;
}

.Feedback-content {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.Feedback-content.drawer-open {
  width: calc(100% - 400px);
}

.Feedback-main {
  flex: 1;
  padding: 20px;
  overflow: hidden;  
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease;
  margin-top: 10px;
  position: fixed;
  height: 100vh; /* Ensure the container has a height */
  width: 100%;
  overflow-y: auto; /* Allow scrolling within the container */
}

.Feedback-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  overflow: hidden; 
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
}

.Feedback-table-header {
  position: sticky;
  top: 0;
  background-color: var(--table-header-background);
  z-index: 1;
  font-weight: bold;
}

.Feedback-table-body {
  overflow-y: auto;
  flex: 1;
}

.Feedback-table-header .Feedback-table-row:hover {
  background-color: var(--table-header-background);
}

.Feedback-table-row {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 2fr 1.5fr 1fr .6fr;
  align-items: center;
  font-size: 0.7em;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.Feedback-table-row.selected-row {
  background-color: var(--table-header-background) !important;
}

.Feedback-table-cell {
  padding: 10px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  position: relative; 
  overflow: visible;   
}

.Feedback-table-row:hover {
  background-color: var(--table-row-hover-background);
}

.Feedback-table .tooltip-text-name,
.Feedback-table .tooltip-text-date {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 5px;
  transform: none;
  z-index: 1000;
}

.Feedback-table .tooltip-container-date,
.Feedback-table .tooltip-container-name {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;  /* Allow container to take full cell width */
  overflow: visible;  /* Allow tooltip to overflow */
}

.Feedback-filter-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative; 
  width: calc(100% - 25px);
  transition: width 0.3s ease;
}

.Feedback-filter-row .styled-search-input {
  width: 100%;
  padding: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.Feedback-search-submit {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}

.search-container-feedback {
  width: 150px;
}

.Feedback-date-range-picker {
  position: absolute;
  top: 75px;
  left: 20px;
  z-index: 1500; 
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--box-shadow-color);
}

.Feedback-submit-date-range {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: var(--button-background);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Feedback-submit-date-range:hover {
  background-color: var(--button-hover-background);
}

.Feedback-table-container {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.Feedback-table-container.drawer-open {
  width: calc(100% - 400px);
}

.Feedback-table-container.drawer-open ~ .Feedback-filter-row {
  width: calc(100% - 400px);
}

.Feedback-drawer {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  margin-top: 48px;
  background-color: var(--background-color);
  transition: right 0.3s ease;
  z-index: 100;
  border-left: 1px solid var(--border-color);
}

.Feedback-drawer.open {
  right: 0;
}

.feedback-count .fa-thumbs-up {
  color: var(--thumb-up-color);
}

.feedback-count .fa-thumbs-down {
  color: var(--thumb-down-color);
}

.feedback-counts {
  display: flex;
  gap: 10px; 
  align-items: center;
}

.feedback-count {
  position: relative;
  display: inline-block;  
}

.feedback-count.wide-count + .feedback-count {
  margin-left: 5px;  
}

.count-badge {
  margin-left: 1px; 
  margin-top: 8px;
  position: absolute;
  background-color: transparent;
  color: var(--text-color);
  font-size: 0.7em;
  min-width: 20px;
  text-align: left;
  padding-right: 25px; 
}

.edited-text {
  font-size: 0.75em;
  color: var(--text-color);
  margin-left: 5px;
  font-style: italic;
  opacity: 0.5;
  position: relative;
  display: flex;
}

.error-message-feedback {
  color: var(--status-needs-review-color);
  margin-bottom: 1em;
  margin-left: 2px;
  font-size: 0.8em;
  position: relative;
}

.no-output-available {
  color: var(--text-color);
  font-size: .8em !important;
  margin-left: 2px;
  margin-top: 5px;
}

.character-count {
  text-align: right;
  font-size: 0.8em;
  color: #666;
  margin-bottom: 8px;
}

.no-metrics-message {
  color: var(--text-color);
  font-size: 1em;
  margin-top: 20px;
  margin-left: 15px;
}

.status-dropdown-container {
  margin-left: auto;  /* This pushes it to the right */
  position: relative;
}

.status-dropdown-container[data-disabled="true"] {
  cursor: pointer;
}

.status-dropdown-container[data-disabled="true"] .status-dropdown-button {
  cursor: pointer;
}

/* Add a new style for the lighter "Needs Review" background when no feedback exists */
.status-dropdown-button[data-status="Needs Review"].no-feedback {
  background-color: rgba(216, 196, 173, 0.5); /* Lighter version of --status-needs-review-color */
}

.status-dropdown-button {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  border-radius: 4px;
  border: none;
  font-size: 0.8em;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-dropdown-button.no-feedback {
  position: relative;
}

.status-dropdown-button.no-feedback:hover::after {
  content: "Add this run to the feedback review list";
  position: absolute;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  padding: 8px; 
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s, background-color 0.3s;
  pointer-events: none;
  bottom: 100%;
  left: -10%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  opacity: 1;
}

:root:not(.dark-mode) .status-dropdown-button.no-feedback:hover::after {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none;
}

.dark-mode .status-dropdown-button.no-feedback:hover::after {
  border: 1px solid white;
  box-shadow: none;
}

.status-dropdown-button .small-chevron {
  margin-left: 8px;
}

.status-dropdown-button[data-status="Needs Review"] {
  background-color: var(--status-needs-review-color);
}

.status-dropdown-button[data-status="Reviewed"] {
  background-color: var(--status-reviewed-color);
}

.status-dropdown-button[data-status="Addressed"] {
  background-color: var(--status-addressed-color);
}

.status-dropdown-button[data-status="Archived"] {
  background-color: var(--status-archived-color);
}

.status-dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  overflow: hidden;
  width: 100%;
}

.status-dropdown-options .dropdown-option {
  padding: 8px;
  cursor: pointer;
}

.status-dropdown-options .dropdown-option:hover {
  background-color: var(--button-hover-background);
}

.tooltip-text-date,
.tooltip-text-description,
.tooltip-text-name {
  visibility: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  text-align: left;
  border-radius: 2px;
  padding: 10px;
  position: fixed;
  z-index: 1000;
  white-space: normal;
  opacity: 0;
  transition: opacity 0.5s;
  max-width: 300px;
}

/* Light mode styles */
:root:not(.dark-mode) .tooltip-text-date,
:root:not(.dark-mode) .tooltip-text-description,
:root:not(.dark-mode) .tooltip-text-name {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none;
}

/* Dark mode styles */
.dark-mode .tooltip-text-date,
.dark-mode .tooltip-text-description,
.dark-mode .tooltip-text-name {
  border: 1px solid white;
  box-shadow: none;
}

/* Keep existing hover states */
.tooltip-container-date:hover .tooltip-text-date,
.tooltip-container-description:hover .tooltip-text-description,
.tooltip-container-name:hover .tooltip-text-name {
  visibility: visible;
  opacity: 1;
  min-width: 120px;
}

/* Keep existing container styles */
.tooltip-container-date,
.tooltip-container-description,
.tooltip-container-name {
  position: relative;
  display: inline-block;
  cursor: pointer;
  min-width: 120px;
}

.App-table .tooltip-text-date {
  margin-top: 15px;
  margin-right: 20px;
  right: 0;
}

.App-table .tooltip-text-description {
  margin-top: 15px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 57%;
}

.App-table .tooltip-text-name {
  margin-top: 15px;
  left: 0;
  margin-left: 28%;
}

.no-feedback-message {
  text-align: center;
  padding: 20px;
  color: var(--text-color);
  font-size: 1.1em;
}

.no-feedback-icon {
  margin-right: 10px;
  opacity: 0.7;
}

.feedback-loading-spinner {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.feedback-loading-spinner .spinner {
  width: 20px;
  height: 20px;
  border: 2px solid var(--border-color);
  border-top: 2px solid var(--link-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}


#show-archived:hover::after {
  content: "Show Archived Feedback";
  left: -60px; 
}

#show-archived:checked:hover::after {
  content: "Hide Archived Feedback"; 
  left: -60px; 
}

#show-archived-workflows:hover::after {
  content: "Show Archived Workflows";
}

#show-archived-workflows:checked:hover::after {
  content: "Show Active Workflows Only";
}

/* Style for all checkbox tooltips */
#show-archived-workflows:hover::after,
#show-archived-workflows:checked:hover::after,
#show-archived:hover::after,
#show-archived:checked:hover::after {
  position: absolute;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  padding: 8px; 
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s, background-color 0.3s;
  pointer-events: none;
  bottom: 100%;
  transform: translateX(-50%);
  opacity: 1;
}

.workflow-table .focused-row {
  background-color: var(--table-row-hover-background);
}

.workflow-table .focused-row:hover {
  background-color: var(--table-row-hover-background);
}

.workflow-link-icon {
  margin-right: 10px;
  color: var(--text-color);
  cursor: pointer;
  font-size: 0.9em;
}

.workflow-link-icon:hover {
  color: var(--link-hover-color);
}

.empty-feedback-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--text-color);
  font-size: 1.4em;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 2px;
  border: 1px solid var(--border-color);
  max-width: 450px;
}